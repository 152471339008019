import React, { Component } from 'react';

import gameBoardStyles from "../style/gameboard.module.css";
import field_blue from "../images/field_blue.png";
import field_green from "../images/field_green.png";
import field_yellow from "../images/field_yellow.png";
import field_red from "../images/field_red.png";
import field_effect_burn from "../images/field_effect_burn.png";
import field_effect_plustwo from "../images/field_effect_plustwo.png";
import field_effect_steal from "../images/field_effect_steal.png";
import field_effect_switch from "../images/field_effect_switch.png";
import player_blue from "../images/player_blue.png";
import player_green from "../images/player_green.png";
import player_red from "../images/player_red.png";
import player_yellow from "../images/player_yellow.png";
import rotation_right from "../images/rotation_right.png";
import rotation_left from "../images/rotation_left.png";

class BoardFieldComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            boardField: props.boardField
        }
    }

    render(){
        let categoryImgUrl = "";
        let actionFieldElement = "";
        let playerFieldElement = "";
        let onClickSection = undefined;
        let idSection = "";
        let animationClass = "";
        const showRotation = this.props.hasOwnProperty("rotationInfo");
        if(this.state.boardField !== null){
            idSection = this.state.boardField.id;

            onClickSection = this.state.boardField.clickable ? this.props.clickAction : undefined;

            animationClass = this.state.boardField.clickable ? gameBoardStyles.animateClickable : "";

            if(this.state.boardField.color === "red")
                categoryImgUrl = `url(${field_red})`;
            else if(this.state.boardField.color === "blue")
                categoryImgUrl = `url(${field_blue})`;
            else if(this.state.boardField.color === "green")
                categoryImgUrl = `url(${field_green})`;
            else if(this.state.boardField.color === "yellow")
                categoryImgUrl = `url(${field_yellow})`;

            if(this.state.boardField.fieldAction === "switch")
                actionFieldElement = <img id={idSection} alt={"switchField"} className={gameBoardStyles.gridImage} src={field_effect_switch}/>;
            else if(this.state.boardField.fieldAction === "steal")
                actionFieldElement = <img id={idSection} alt={"stealField"} className={gameBoardStyles.gridImage} src={field_effect_steal}/>;
            else if(this.state.boardField.fieldAction === "burn")
                actionFieldElement = <img id={idSection} alt={"burnField"} className={gameBoardStyles.gridImage} src={field_effect_burn}/>;
            else if(this.state.boardField.fieldAction === "plustwo")
                actionFieldElement = <img id={idSection} alt={"plsTwoField"} className={gameBoardStyles.gridImage} src={field_effect_plustwo}/>

            if(this.state.boardField.player !== null){
                if(this.state.boardField.player.playerNumber === 1)
                    playerFieldElement = <img id={idSection} alt={"playerOne"} className={gameBoardStyles.gridImage} src={player_red}/>;
                else if(this.state.boardField.player.playerNumber === 2)
                    playerFieldElement = <img id={idSection} alt={"playerTwo"} className={gameBoardStyles.gridImage} src={player_blue}/>;
                else if(this.state.boardField.player.playerNumber === 3)
                    playerFieldElement = <img id={idSection} alt={"playerThree"} className={gameBoardStyles.gridImage} src={player_green}/>;
                else if(this.state.boardField.player.playerNumber === 4)
                    playerFieldElement = <img id={idSection} alt={"playerFour"} className={gameBoardStyles.gridImage} src={player_yellow}/>;
            }
        }
        return (
            <div >
                {(this.state.boardField !== null) &&
                <div id={idSection} onClick={onClickSection}
                     className={`${gameBoardStyles.gridContent} ${animationClass}`}
                     style={{backgroundImage: categoryImgUrl, backgroundSize: "contain"}}>
                    {actionFieldElement}
                    {playerFieldElement}
                </div>
                }
                {(showRotation) &&
                    <div className={`${gameBoardStyles.gridContent}`}
                         style={{backgroundImage: (this.props.rotationInfo === 1) ? `url(${rotation_right})` : `url(${rotation_left})`, backgroundSize: "contain"}}>
                    </div>
                }
            </div>
        );
    }
}

export default BoardFieldComponent;
