import Gameboard from "./gameboard";

export default class Game {
    constructor() {
        this.started = false;
        this.gameSettings = null;
        this.gameBoard = null;
        this._rotation = 1;
        this.currentPlayerTurn = null;
        this.playerOne = null;
        this.playerTwo = null;
        this.playerThree = null;
        this.playerFour = null;
        this.currentQuestion = null;
        this.winner = null;
    }

    get rotation(){return this._rotation;}

    initNewGame(gameSettings){
        this.gameSettings = gameSettings;
        this.playerOne = new Player(gameSettings.playerOne, 1);
        this.playerTwo = new Player(gameSettings.playerTwo, 2);
        this.playerThree = new Player(gameSettings.playerThree, 3);
        this.playerFour = new Player(gameSettings.playerFour, 4);
        this.gameBoard = new Gameboard();
        this.gameBoard.initGameBoard(this.gameSettings, this.playerOne, this.playerTwo, this.playerThree, this.playerFour);
        this.playerOne.currentField = this.gameBoard.boardFields["c3"];
        this.playerOne.goalField = this.gameBoard.boardFields["i9"];
        this.playerTwo.currentField = this.gameBoard.boardFields["c7"];
        this.playerTwo.goalField = this.gameBoard.boardFields["i1"];
        this.playerThree.currentField = this.gameBoard.boardFields["g7"];
        this.playerThree.goalField = this.gameBoard.boardFields["a1"];
        this.playerFour.currentField = this.gameBoard.boardFields["g3"];
        this.playerFour.goalField = this.gameBoard.boardFields["a9"];
        this.started = true;

        this.calculateStartingPlayer();
    }

    calculateStartingPlayer(){
        let rndPlayer = Math.floor(Math.random() * 4) + 1;
        switch(rndPlayer){
            case 1:
                this.currentPlayerTurn = this.playerOne;
                break;
            case 2:
                this.currentPlayerTurn = this.playerTwo;
                break;
            case 3:
                this.currentPlayerTurn = this.playerThree;
                break;
            case 4:
                this.currentPlayerTurn = this.playerFour;
                break;
            default:
                this.currentPlayerTurn = this.playerOne;
                break;
        }
    }

    nextTurn(){
        this.currentQuestion = null;
        this.currentPlayerTurn.countCorrectAnswers = 0;
        this.currentPlayerTurn.stealsFrom = null;
        switch(this.currentPlayerTurn.playerNumber){
            case 1:
                this.currentPlayerTurn = (this.rotation === 1) ? this.playerTwo : this.playerFour;
                break;
            case 2:
                this.currentPlayerTurn = (this.rotation === 1) ? this.playerThree : this.playerOne;
                break;
            case 3:
                this.currentPlayerTurn = (this.rotation === 1) ? this.playerFour : this.playerTwo;
                break;
            case 4:
                this.currentPlayerTurn = (this.rotation === 1) ? this.playerOne : this.playerThree;
                break;
            default:
                break;
        }
    }

    rollD6(){
        return Math.floor(Math.random() * 6) + 1;
    }

    switchRotation(){
        this._rotation = (this._rotation === 1) ? -1 : 1;
    }

    hasAlreadyCategory(){
        switch(this.currentPlayerTurn.currentField.category.categoryNumber) {
            case 1:
                return this.currentPlayerTurn.categoryOne;
            case 2:
                return this.currentPlayerTurn.categoryTwo;
            case 3:
                return this.currentPlayerTurn.categoryThree;
            case 4:
                return this.currentPlayerTurn.categoryFour;
            default:
                return false;
        }
    }

    calcMoveableFields(diceResult){
        let currentField = this.currentPlayerTurn.currentField;
        let fields = this.getMoveableFields(null, currentField, diceResult);
        fields.forEach((fieldId) => {
            this.gameBoard.boardFields[fieldId].clickable = true;
        })
        return this;
    }

    removeBurnEffect(){
        switch(this.currentPlayerTurn.playerNumber){
            case 1:
                this.playerOne.burn = false;
                break;
            case 2:
                this.playerTwo.burn = false;
                break;
            case 3:
                this.playerThree.burn = false;
                break;
            case 4:
                this.playerFour.burn = false;
                break;
            default:
                break;
        }
    }

    setStealing(playerId){
        switch(parseInt(playerId)){
            case 1:
                this.currentPlayerTurn.stealsFrom = this.playerOne;
                break;
            case 2:
                this.currentPlayerTurn.stealsFrom = this.playerTwo;
                break;
            case 3:
                this.currentPlayerTurn.stealsFrom = this.playerThree;
                break;
            case 4:
                this.currentPlayerTurn.stealsFrom = this.playerFour;
                break;
            default:
                break;
        }
    }

    setBurning(playerId){
        switch(parseInt(playerId)){
            case 1:
                this.playerOne.burn = true;
                break;
            case 2:
                this.playerTwo.burn = true;
                break;
            case 3:
                this.playerThree.burn = true;
                break;
            case 4:
                this.playerFour.burn = true;
                break;
            default:
                break;
        }
    }

    setPlusTwo(playerId){
        switch(parseInt(playerId)){
            case 1:
                this.playerOne.plusTwo = true;
                break;
            case 2:
                this.playerTwo.plusTwo = true;
                break;
            case 3:
                this.playerThree.plusTwo = true;
                break;
            case 4:
                this.playerFour.plusTwo = true;
                break;
            default:
                break;
        }
    }

    stealSuccess(){
        switch(this.currentQuestion.category.categoryNumber){
            case 1:
                this.currentPlayerTurn.categoryOne = true;
                this.currentPlayerTurn.stealsFrom.categoryOne = false;
                break;
            case 2:
                this.currentPlayerTurn.categoryTwo = true;
                this.currentPlayerTurn.stealsFrom.categoryTwo = false;
                break;
            case 3:
                this.currentPlayerTurn.categoryThree = true;
                this.currentPlayerTurn.stealsFrom.categoryThree = false;
                break;
            case 4:
                this.currentPlayerTurn.categoryFour = true;
                this.currentPlayerTurn.stealsFrom.categoryFour = false;
                break;
            default:
                break;
        }
    }

    quizSuccess(){
        switch(this.currentQuestion.category.categoryNumber){
            case 1:
                this.currentPlayerTurn.categoryOne = true;
                break;
            case 2:
                this.currentPlayerTurn.categoryTwo = true;
                break;
            case 3:
                this.currentPlayerTurn.categoryThree = true;
                break;
            case 4:
                this.currentPlayerTurn.categoryFour = true;
                break;
            default:
                break;
        }
    }

    getMoveableFields(previousField, currentField, steps){
        let fields = [];
        if (steps <= 0) {
            if (currentField.player === null) {
                fields.push(currentField.id);
                return fields;
            }
        }
        currentField.adjFields.forEach((field) => {
            if (previousField === null) {
                fields = fields.concat(this.getMoveableFields(currentField, field, steps - 1))
            } else {
                if (field.id !== previousField.id) {
                    fields = fields.concat(this.getMoveableFields(currentField, field, steps - 1))
                }
            }
        })
        return fields;
    }

    hasPlayerWon(){
        if(this.currentPlayerTurn.currentField.id === this.currentPlayerTurn.goalField.id) {
            if(this.currentPlayerTurn.categoryOne && this.currentPlayerTurn.categoryTwo &&
                this.currentPlayerTurn.categoryThree && this.currentPlayerTurn.categoryFour){
                this.started = false;
                this.winner = this.currentPlayerTurn;
                return true;
            }
        }
        return false;
    }

    async fetchQuestion(category) {
        let data;

        try {
            const response = await fetch(`https://opentdb.com/api.php?amount=1&category=${category.categoryId}&difficulty=${this.gameSettings.difficultyLevel}&type=multiple`);
            data = await response.json();
            const questionObject = data.results[0];
            let answerOne = "";
            let answerTwo = "";
            let answerThree = "";
            let answerFour = "";
            const correctPosition = Math.floor(Math.random() * 4) + 1;
            switch(correctPosition){
                case 1:
                    answerOne = questionObject.correct_answer;
                    answerTwo = questionObject.incorrect_answers[0];
                    answerThree = questionObject.incorrect_answers[1];
                    answerFour = questionObject.incorrect_answers[2];
                    break;
                case 2:
                    answerOne = questionObject.incorrect_answers[0];
                    answerTwo = questionObject.correct_answer;
                    answerThree = questionObject.incorrect_answers[1];
                    answerFour = questionObject.incorrect_answers[2];
                    break;
                case 3:
                    answerOne = questionObject.incorrect_answers[0];
                    answerTwo = questionObject.incorrect_answers[1];
                    answerThree = questionObject.correct_answer;
                    answerFour = questionObject.incorrect_answers[2];
                    break;
                case 4:
                    answerOne = questionObject.incorrect_answers[0];
                    answerTwo = questionObject.incorrect_answers[1];
                    answerThree = questionObject.incorrect_answers[2];
                    answerFour = questionObject.correct_answer;
                    break;
                default:
                    answerOne = questionObject.correct_answer;
                    answerTwo = questionObject.incorrect_answers[0];
                    answerThree = questionObject.incorrect_answers[1];
                    answerFour = questionObject.incorrect_answers[2];
                    break;
            }

            this.currentQuestion = new Question(category, questionObject.question, answerOne, answerTwo, answerThree, answerFour, correctPosition);
        } catch (error) {
            console.log('error', error);
        }
    }
}

class Player{
    constructor(name, number) {
        this.playerName = name;
        this.playerNumber = number;
        this.categoryOne = false;
        this.categoryTwo = false;
        this.categoryThree = false;
        this.categoryFour = false;
        this.plusTwo = false;
        this.burn = false;
        this.currentField = null;
        this.countCorrectAnswers = 0;
        this.stealsFrom = null;
        this.goalField = null;
    }

    canStealCategory(categoryNumber){
        switch (categoryNumber){
            case 1:
                return this.categoryOne;
            case 2:
                return this.categoryTwo;
            case 3:
                return this.categoryThree;
            case 4:
                return this.categoryFour;
            default:
                return false;
        }
    }
}

class Question{
    constructor(category, question, answerOne, answerTwo, answerThree, answerFour, correctAnswer) {
        this.category = category;
        this.question = question;
        this.answerOne = answerOne;
        this.answerTwo = answerTwo;
        this.answerThree = answerThree;
        this.answerFour = answerFour;
        this.correctAnswer = correctAnswer;
    }
}
