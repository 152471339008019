import React, {Component} from 'react';
import GameComponent from './components/Game.component';
import SettingsComponent from './components/Settings.component';
import Game from "./game/game";
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 'SETTINGS',
            activeGame: new Game()
        };

        this.mainStyle = {
            textAlign: 'center',
            color: '#C83838',
            margin: '1em'
        }
    };

    render = () => {
        const active = this.state.activePage;
        return (
            <div className="main" style={this.mainStyle}>
                <h1>Knowledge Hell</h1>
                {(active === 'SETTINGS') && <SettingsComponent game={this.state.activeGame} setState={p=>{this.setState(p)}} /> }
                {(active === 'GAME') && <GameComponent game={this.state.activeGame} setState={p=>{this.setState(p)}} /> }
            </div>
        );
    }

}

export default App;
