import React, { Component } from 'react';

import {Modal, Col, Row, Button} from "react-bootstrap";
import info_player_red from "../images/info_player_red.png";
import info_player_blue from "../images/info_player_blue.png";
import info_player_green from "../images/info_player_green.png";
import info_player_yellow from "../images/info_player_yellow.png";

class PlusTwoModalComponent extends Component {

    render() {
        let playersToChoose = [];
        let buttonPlayerOne = "";
        let buttonPlayerTwo = "";
        let buttonPlayerThree = "";
        let buttonPlayerFour = "";

        if(this.props.playerOne.plusTwo === false && this.props.playerOne.playerNumber !== this.props.currentPlayer.playerNumber){
            playersToChoose.push(this.props.playerOne);
            buttonPlayerOne = <Row className={"mt-2"}>
                <Col md={12}>
                    <Button size={"sm"} style={{width: '10em'}} variant="danger" as="input"
                            type="button" id={this.props.playerOne.playerNumber} value={this.props.playerOne.playerName} onClick={this.props.plusTwoAction} />
                    <img style={{marginLeft: "0.3em", width:"26px"}} alt={"playerImg"} src={info_player_red} />
                </Col>
            </Row>;
        }
        if(this.props.playerTwo.plusTwo === false && this.props.playerTwo.playerNumber !== this.props.currentPlayer.playerNumber){
            playersToChoose.push(this.props.playerTwo);
            buttonPlayerTwo = <Row className={"mt-2"}>
                <Col md={12}>
                    <Button size={"sm"} style={{width: '10em'}} variant="danger" as="input"
                            type="button" id={this.props.playerTwo.playerNumber} value={this.props.playerTwo.playerName} onClick={this.props.plusTwoAction} />
                    <img style={{marginLeft: "0.3em", width:"26px"}} alt={"playerImg"} src={info_player_blue} />
                </Col>
            </Row>;
        }
        if(this.props.playerThree.plusTwo === false && this.props.playerThree.playerNumber !== this.props.currentPlayer.playerNumber){
            playersToChoose.push(this.props.playerThree);
            buttonPlayerThree = <Row className={"mt-2"}>
                <Col md={12}>
                    <Button size={"sm"} style={{width: '10em'}} variant="danger" as="input"
                            type="button" id={this.props.playerThree.playerNumber} value={this.props.playerThree.playerName} onClick={this.props.plusTwoAction} />
                    <img style={{marginLeft: "0.3em", width:"26px"}} alt={"playerImg"} src={info_player_green} />

                </Col>
            </Row>;
        }
        if(this.props.playerFour.plusTwo === false && this.props.playerFour.playerNumber !== this.props.currentPlayer.playerNumber){
            playersToChoose.push(this.props.playerFour);
            buttonPlayerFour = <Row className={"mt-2"}>
                <Col md={12}>
                    <Button size={"sm"} style={{width: '10em'}} variant="danger" as="input"
                            type="button" id={this.props.playerFour.playerNumber} value={this.props.playerFour.playerName} onClick={this.props.plusTwoAction} />
                    <img style={{marginLeft: "0.3em", width:"26px"}} alt={"playerImg"} src={info_player_yellow} />
                </Col>
            </Row>;
        }

        return (
            <Modal show={true} size="md">
                <Modal.Header>
                    <Modal.Title>Choose player to give +2</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {(playersToChoose.length === 0) &&
                        <Row>
                            <Col md={12} className={"text-center"}>
                                <h4>Sadly no players to give +2</h4>
                            </Col>
                        </Row>
                        }

                        {(playersToChoose.length > 0) &&
                        <Row>
                            <Col md={12} className={"text-center"}>
                                <div>
                                    {buttonPlayerOne}
                                </div>
                                <div>
                                    {buttonPlayerTwo}
                                </div>
                                <div>
                                    {buttonPlayerThree}
                                </div>
                                <div>
                                    {buttonPlayerFour}
                                </div>
                            </Col>
                        </Row>
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {(playersToChoose.length === 0) &&
                        <Button size={"sm"} style={{width: '10em', marginRight: '1em'}} variant="danger" as="input"
                                type="button" value="Close" onClick={this.props.closePlusTwoModal}/>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PlusTwoModalComponent;
