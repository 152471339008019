class Gameboard{
    constructor() {
        this.boardFields = [];
    }

    removeClickable(){
        for (const boardFieldIndex in this.boardFields) {
            if(this.boardFields.hasOwnProperty(boardFieldIndex)){
                this.boardFields[boardFieldIndex].clickable = false;
            }
        }
    }

    initGameBoard = (settings, playerOne, playerTwo, playerThree, playerFour) => {
        //Initialize all fields
        let a1 = new BoardField("a1", settings.categoryThree, "green", null, null);
        let a2 = new BoardField("a2", settings.categoryFour, "yellow", "switch", null);
        let a3 = new BoardField("a3", settings.categoryOne, "red", null, null);
        let a4 = new BoardField("a4", settings.categoryTwo, "blue", null, null);
        let a5 = new BoardField("a5", settings.categoryFour, "yellow", "burn", null);
        let a6 = new BoardField("a6", settings.categoryOne, "red", null, null);
        let a7 = new BoardField("a7", settings.categoryTwo, "blue", null, null);
        let a8 = new BoardField("a8", settings.categoryThree, "green", "steal", null);
        let a9 = new BoardField("a9", settings.categoryFour, "yellow", null, null);

        let b1 = new BoardField("b1", settings.categoryTwo, "blue", "steal", null);
        let b5 = new BoardField("b5", settings.categoryThree, "green", null, null);
        let b9 = new BoardField("b9", settings.categoryOne, "red", "switch", null);

        let c1 = new BoardField("c1", settings.categoryOne, "red", null, null);
        let c3 = new BoardField("c3", settings.categoryOne, "red", null, playerOne);
        let c4 = new BoardField("c4", settings.categoryFour, "yellow", null, null);
        let c5 = new BoardField("c5", settings.categoryTwo, "blue", "steal", null);
        let c6 = new BoardField("c6", settings.categoryFour, "yellow", null, null);
        let c7 = new BoardField("c7", settings.categoryTwo, "blue", null, playerTwo);
        let c9 = new BoardField("c9", settings.categoryTwo, "blue", null, null);

        let d1 = new BoardField("d1", settings.categoryFour, "yellow", null, null);
        let d3 = new BoardField("d3", settings.categoryThree, "green", "plustwo", null);
        let d7 = new BoardField("d7", settings.categoryOne, "red", "switch", null);
        let d9 = new BoardField("d9", settings.categoryThree, "green", null, null);

        let e1 = new BoardField("e1", settings.categoryThree, "green", "burn", null);
        let e2 = new BoardField("e2", settings.categoryTwo, "blue", null, null);
        let e3 = new BoardField("e3", settings.categoryOne, "red", null, null);
        let e7 = new BoardField("e7", settings.categoryThree, "green", null, null);
        let e8 = new BoardField("e8", settings.categoryFour, "yellow", null, null);
        let e9 = new BoardField("e9", settings.categoryOne, "red", "burn", null);

        let f1 = new BoardField("f1", settings.categoryOne, "red", null, null);
        let f3 = new BoardField("f3", settings.categoryThree, "green", "switch", null);
        let f7 = new BoardField("f7", settings.categoryOne, "red", "plustwo", null);
        let f9 = new BoardField("f9", settings.categoryTwo, "blue", null, null);

        let g1 = new BoardField("g1", settings.categoryFour, "yellow", null, null);
        let g3 = new BoardField("g3", settings.categoryFour, "yellow", null, playerFour);
        let g4 = new BoardField("g4", settings.categoryTwo, "blue", null, null);
        let g5 = new BoardField("g5", settings.categoryFour, "yellow", "steal", null);
        let g6 = new BoardField("g6", settings.categoryTwo, "blue", null, null);
        let g7 = new BoardField("g7", settings.categoryThree, "green", null, playerThree);
        let g9 = new BoardField("g9", settings.categoryThree, "green", null, null);

        let h1 = new BoardField("h1", settings.categoryThree, "green", "switch", null);
        let h5 = new BoardField("h5", settings.categoryOne, "red", null, null);
        let h9 = new BoardField("h9", settings.categoryFour, "yellow", "steal", null);

        let i1 = new BoardField("i1", settings.categoryTwo, "blue", null, null);
        let i2 = new BoardField("i2", settings.categoryOne, "red", "steal", null);
        let i3 = new BoardField("i3", settings.categoryFour, "yellow", null, null);
        let i4 = new BoardField("i4", settings.categoryThree, "green", null, null);
        let i5 = new BoardField("i5", settings.categoryTwo, "blue", "burn", null);
        let i6 = new BoardField("i6", settings.categoryFour, "yellow", null, null);
        let i7 = new BoardField("i7", settings.categoryThree, "green", null, null);
        let i8 = new BoardField("i8", settings.categoryTwo, "blue", "switch", null);
        let i9 = new BoardField("i9", settings.categoryOne, "red", null, null);

        //set adjacent fields for each field
        a1.adjFields = [a2, b1];
        a2.adjFields = [a1, a3];
        a3.adjFields = [a2, a4];
        a4.adjFields = [a3, a5];
        a5.adjFields = [a4, a6, b5];
        a6.adjFields = [a5, a7];
        a7.adjFields = [a6, a8];
        a8.adjFields = [a7, a9];
        a9.adjFields = [a8, b9];
        this.boardFields["a1"] = a1;
        this.boardFields["a2"] = a2;
        this.boardFields["a3"] = a3;
        this.boardFields["a4"] = a4;
        this.boardFields["a5"] = a5;
        this.boardFields["a6"] = a6;
        this.boardFields["a7"] = a7;
        this.boardFields["a8"] = a8;
        this.boardFields["a9"] = a9;

        b1.adjFields = [a1, c1];
        b5.adjFields = [a5, c5];
        b9.adjFields = [a9, c9];
        this.boardFields["b1"] = b1;
        this.boardFields["b5"] = b5;
        this.boardFields["b9"] = b9;

        c1.adjFields = [b1, d1];
        c3.adjFields = [c4, d3];
        c4.adjFields = [c3, c5];
        c5.adjFields = [c4, c6, b5];
        c6.adjFields = [c5, c7];
        c7.adjFields = [c6, d7];
        c9.adjFields = [b9, d9];
        this.boardFields["c1"] = c1;
        this.boardFields["c3"] = c3;
        this.boardFields["c4"] = c4;
        this.boardFields["c5"] = c5;
        this.boardFields["c6"] = c6;
        this.boardFields["c7"] = c7;
        this.boardFields["c9"] = c9;

        d1.adjFields = [c1, e1];
        d3.adjFields = [c3, e3];
        d7.adjFields = [c7, e7];
        d9.adjFields = [c9, e9];
        this.boardFields["d1"] = d1;
        this.boardFields["d3"] = d3;
        this.boardFields["d7"] = d7;
        this.boardFields["d9"] = d9;

        e1.adjFields = [d1, f1, e2];
        e2.adjFields = [e1, e3];
        e3.adjFields = [e2, d3, f3];
        e7.adjFields = [e8, d7, f7];
        e8.adjFields = [e7, e9];
        e9.adjFields = [e8, d9, f9];
        this.boardFields["e1"] = e1;
        this.boardFields["e2"] = e2;
        this.boardFields["e3"] = e3;
        this.boardFields["e7"] = e7;
        this.boardFields["e8"] = e8;
        this.boardFields["e9"] = e9;

        f1.adjFields = [e1, g1];
        f3.adjFields = [e3, g3];
        f7.adjFields = [e7, g7];
        f9.adjFields = [e9, g9];
        this.boardFields["f1"] = f1;
        this.boardFields["f3"] = f3;
        this.boardFields["f7"] = f7;
        this.boardFields["f9"] = f9;

        g1.adjFields = [f1, h1];
        g3.adjFields = [f3, g4];
        g4.adjFields = [g3, g5];
        g5.adjFields = [g4, g6, h5];
        g6.adjFields = [g5, g7];
        g7.adjFields = [g6, f7];
        g9.adjFields = [f9, h9];
        this.boardFields["g1"] = g1;
        this.boardFields["g3"] = g3;
        this.boardFields["g4"] = g4;
        this.boardFields["g5"] = g5;
        this.boardFields["g6"] = g6;
        this.boardFields["g7"] = g7;
        this.boardFields["g9"] = g9;

        h1.adjFields = [g1, i1];
        h5.adjFields = [g5, i5];
        h9.adjFields = [g9, i9];
        this.boardFields["h1"] = h1;
        this.boardFields["h5"] = h5;
        this.boardFields["h9"] = h9;

        i1.adjFields = [h1, i2];
        i2.adjFields = [i1, i3];
        i3.adjFields = [i2, i4];
        i4.adjFields = [i3, i5];
        i5.adjFields = [i4, i6, h5];
        i6.adjFields = [i5, i7];
        i7.adjFields = [i6, i8];
        i8.adjFields = [i7, i9];
        i9.adjFields = [i8, h9];
        this.boardFields["i1"] = i1;
        this.boardFields["i2"] = i2;
        this.boardFields["i3"] = i3;
        this.boardFields["i4"] = i4;
        this.boardFields["i5"] = i5;
        this.boardFields["i6"] = i6;
        this.boardFields["i7"] = i7;
        this.boardFields["i8"] = i8;
        this.boardFields["i9"] = i9;
    }
}

class BoardField{
    constructor(id, category, color, fieldAction, player=null, clickable=false) {
        this.id = id;
        this.category = category;
        this.color = color;
        this.fieldAction = fieldAction;
        this.player = player;
        this.clickable = false;
        this.adjFields = [];
    }
}

export default Gameboard;
