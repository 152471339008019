import React, { Component } from 'react';

import BoardFieldComponent from './BoardField.component';
import {Col, Container, Row, Button} from "react-bootstrap";
import gameBoardStyles from "../style/gameboard.module.css";

import info_player_blue from "../images/info_player_blue.png";
import info_player_green from "../images/info_player_green.png";
import info_player_red from "../images/info_player_red.png";
import info_player_yellow from "../images/info_player_yellow.png";

import HelpComponent from "./Help.component";
import InfoComponent from "./Info.component";
import QuestionComponent from "./Question.component";
import BurnModalComponent from "./BurnModal.component";
import StealModalComponent from "./StealModal.component";
import PlusTwoModalComponent from "./PlusTwoModal.component";
import WinningModalComponent from "./WinningModal.component";
import EndGameModalComponent from "./EndGameModal.component";

class GameComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            game: props.game,
            showHelp: false,
            showTurnInfo: true,
            showTurnInfoText: "",
            showQuiz: false,
            showCorrectAnswer: false,
            playerAnswer: null,
            quizClickable: true,
            showRollDiceContainer: true,
            showDiceResult: false,
            diceResult: 0,
            showProgressbar: false,
            showBurnModal: false,
            showStealModal: false,
            showPlusTwoModal: false,
            showWinningModal: false,
            showEndGame: false
        }
    }

    showHelp = () => {
        this.setState({
          showHelp: true
        })
    }

    closeHelp = () => {
        this.setState({
            showHelp: false
        })
    }

    showEndGame = () => {
        this.setState({
            showEndGame: true
        })
    }

    closeEndGame = () => {
        this.setState({
            showEndGame: false
        })
    }

    diceAction = () => {
        const diceResult = this.state.game.rollD6();
        this.setState({
            diceResult: diceResult,
            showDiceResult: true
        });

        let game = this.state.game.calcMoveableFields(diceResult);
        this.setState({
            game: game
        });
    }

    quizAction = (event) => {
        const target = event.target;
        const answerNumber = target.getAttribute('answernumber');
        this.setState({
            quizClickable: false,
            showCorrectAnswer: true,
            playerAnswer: answerNumber
        });

        let game = this.state.game;

        if(parseInt(answerNumber) === game.currentQuestion.correctAnswer){
            game.currentPlayerTurn.countCorrectAnswers += 1;

            //check if player has +2
            if(game.currentPlayerTurn.plusTwo){
                if(game.currentPlayerTurn.countCorrectAnswers === 2){
                    game.currentPlayerTurn.plusTwo = false;
                    this.setState({
                        game: game
                    });
                }
                else{
                    this.setState({
                        game: game,
                        showProgressbar: true,
                        showTurnInfoText: "Player has to answer another question"
                    });

                    setTimeout(this.showQuestion, 10000);
                    return;
                }
            }

            if(game.currentPlayerTurn.stealsFrom !== null){
                //steal token from target player
                game.stealSuccess();
                this.setState({
                    game: game,
                    showProgressbar: true,
                    showTurnInfoText: "Player steals successfully"
                });

                setTimeout(this.nextTurn, 10000);
            }
            else{
                game.quizSuccess();
                this.setState({
                    game: game,
                    showProgressbar: true
                });

                setTimeout(this.nextTurn, 10000);
            }
        }
        else{
            this.setState({
                showProgressbar: true
            });

            setTimeout(this.nextTurn, 10000);
        }
    }

    moveAction = (event) => {
        let game = this.state.game;
        game.gameBoard.removeClickable();
        game.gameBoard.boardFields[event.target.id].player = game.currentPlayerTurn;
        game.gameBoard.boardFields[game.currentPlayerTurn.currentField.id].player = null;
        game.currentPlayerTurn.currentField = game.gameBoard.boardFields[event.target.id];
        this.setState({
            game: game
        });

        this.setState({
            showDiceResult: false,
            showRollDiceContainer: false
        });

        if(game.hasPlayerWon()){
            //game finished
            this.setState({
                game: game,
                showWinningModal: true
            });
            return;
        }

        if(game.currentPlayerTurn.currentField.fieldAction !== null){
            if(game.currentPlayerTurn.currentField.fieldAction === "burn"){
                this.setState({
                    showBurnModal: true
                });
            }
            else if(game.currentPlayerTurn.currentField.fieldAction === "switch"){
                game.switchRotation();
                this.setState({
                    game: game
                });
                this.decideNextTurnOrQuestion();
            }
            else if(game.currentPlayerTurn.currentField.fieldAction === "steal"){
                if(game.hasAlreadyCategory()){
                    this.decideNextTurnOrQuestion();
                }
                else{
                    this.setState({
                        showStealModal: true
                    });
                }
            }
            else if(game.currentPlayerTurn.currentField.fieldAction === "plustwo"){
                this.setState({
                    showPlusTwoModal: true
                });
            }
        }
        else{
            this.decideNextTurnOrQuestion();
        }
    }

    nextTurn = () => {
        let game = this.state.game;
        game.nextTurn();
        this.setState({
            game: game,
            showTurnInfoText: "",
            showDiceResult: false,
            showRollDiceContainer: true,
            showQuiz: false,
            showCorrectAnswer: false,
            playerAnswer: null,
            quizClickable: true,
            showProgressbar: false
        });

        if(game.currentPlayerTurn.burn === true){
            this.setState({
                showTurnInfoText: "Player is burned",
                showProgressbar: true,
                showRollDiceContainer: false
            });

            game.removeBurnEffect();
            this.setState({
                game: game
            });
            setTimeout(this.nextTurn, 10000);
        }
    }

    showQuestion = () => {
        this.state.game.fetchQuestion(this.state.game.currentPlayerTurn.currentField.category).then(() => {
            let game = this.state.game;
            this.setState({
                game: game,
                quizClickable: true,
            });
        });

        this.setState({
            showQuiz: true,
            playerAnswer: null,
            showCorrectAnswer: false,
            showProgressbar: false,
            showTurnInfoText: ""
        });
    }

    burnAction = (event) => {
        const playerId = event.target.id;
        let game = this.state.game;
        game.setBurning(playerId);
        this.setState({
            showBurnModal: false,
            game: game
        });

        this.decideNextTurnOrQuestion();
    }

    stealAction = (event) => {
        const playerId = event.target.id;
        let game = this.state.game;
        game.setStealing(playerId);
        this.setState({
            showStealModal: false,
            game: game
        });

        this.decideNextTurnOrQuestion();
    }

    plusTwoAction = (event) => {
        const playerId = event.target.id;
        let game = this.state.game;
        game.setPlusTwo(playerId);
        this.setState({
            showPlusTwoModal: false,
            game: game
        });

        this.decideNextTurnOrQuestion();
    }

    decideNextTurnOrQuestion(){
        let game = this.state.game;
        let hasAlreadyCategory = game.hasAlreadyCategory();

        if(!hasAlreadyCategory){
            this.showQuestion();
        }
        else{
            this.setState({
                showTurnInfoText: "Player already has this category token",
                showProgressbar: true
            });
            setTimeout(this.nextTurn, 10000);
        }
    }

    closeBurnModal = () => {
        this.setState({
            showBurnModal: false
        });

        this.decideNextTurnOrQuestion();
    }

    closeStealModal = () => {
        this.setState({
            showStealModal: false
        });

        this.decideNextTurnOrQuestion();
    }

    closePlusTwoModal = () => {
        this.setState({
            showPlusTwoModal: false
        });

        this.decideNextTurnOrQuestion();
    }

    endGame = () => {
        let game = this.state.game;
        game.started = false;
        this.setState({
            game: game
        });
        this.props.setState({activePage: 'SETTINGS'});
    }

    render(){
        return (
            <div style={{textAlign: "left"}}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div style={{textAlign: "right"}}>
                                <Button id={"gameRulesButton"} size={"sm"} style={{width: '10em', marginRight: '1em'}} variant="danger" as="input" type="button" value="Game Rules" onClick={this.showHelp} />
                                <Button id={"endGameConfirmButton"} size={"sm"} style={{width: '10em'}} variant="danger" as="input" type="button" value="End Game" onClick={this.showEndGame} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <div style={{textAlign: "middle", marginTop: "1em"}}>
                                <div className={gameBoardStyles.gridContainer}>
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}} boardField={this.state.game.gameBoard.boardFields["a1"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["a2"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["a3"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["a4"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["a5"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["a6"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["a7"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["a8"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["a9"]} />
                                </div>
                                <div className={gameBoardStyles.gridContainer}>
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["b1"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["b5"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["b9"]} />
                                </div>
                                <div className={gameBoardStyles.gridContainer}>
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["c1"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["c3"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["c4"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["c5"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["c6"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["c7"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["c9"]} />
                                </div>
                                <div className={gameBoardStyles.gridContainer}>
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["d1"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["d3"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["d7"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["d9"]} />
                                </div>
                                <div className={gameBoardStyles.gridContainer}>
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["e1"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["e2"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["e3"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent boardField={null} rotationInfo={this.state.game.rotation} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["e7"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["e8"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["e9"]} />
                                </div>
                                <div className={gameBoardStyles.gridContainer}>
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["f1"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["f3"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["f7"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["f9"]} />
                                </div>
                                <div className={gameBoardStyles.gridContainer}>
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["g1"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["g3"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["g4"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["g5"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["g6"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["g7"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["g9"]} />
                                </div>
                                <div className={gameBoardStyles.gridContainer}>
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["h1"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["h5"]} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent boardField={null} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["h9"]} />
                                </div>
                                <div className={gameBoardStyles.gridContainer}>
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["i1"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["i2"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["i3"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["i4"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["i5"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["i6"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["i7"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["i8"]} />
                                    <BoardFieldComponent clickAction={p=>{this.moveAction(p)}}  boardField={this.state.game.gameBoard.boardFields["i9"]} />
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <InfoComponent playerOne={this.state.game.playerOne} playerTwo={this.state.game.playerTwo}
                                           playerThree={this.state.game.playerThree} playerFour={this.state.game.playerFour}
                                           categoryOne={this.state.game.gameSettings.categoryOne}
                                           categoryTwo={this.state.game.gameSettings.categoryTwo}
                                           categoryThree={this.state.game.gameSettings.categoryThree}
                                           categoryFour={this.state.game.gameSettings.categoryFour} />
                        </Col>
                    </Row>
                    {(this.state.showBurnModal) &&
                        <BurnModalComponent closeBurnModal={this.closeBurnModal} burnAction={this.burnAction} playerOne={this.state.game.playerOne} playerTwo={this.state.game.playerTwo} playerThree={this.state.game.playerThree} playerFour={this.state.game.playerFour} currentPlayer={this.state.game.currentPlayerTurn} />
                    }
                    {(this.state.showStealModal) &&
                        <StealModalComponent closeStealModal={this.closeStealModal} stealAction={this.stealAction} playerOne={this.state.game.playerOne} playerTwo={this.state.game.playerTwo} playerThree={this.state.game.playerThree} playerFour={this.state.game.playerFour} currentPlayer={this.state.game.currentPlayerTurn} />
                    }
                    {(this.state.showPlusTwoModal) &&
                        <PlusTwoModalComponent closePlusTwoModal={this.closePlusTwoModal} plusTwoAction={this.plusTwoAction} playerOne={this.state.game.playerOne} playerTwo={this.state.game.playerTwo} playerThree={this.state.game.playerThree} playerFour={this.state.game.playerFour} currentPlayer={this.state.game.currentPlayerTurn} />
                    }
                    {(this.state.showHelp) &&
                        <HelpComponent closeHelp={this.closeHelp} />
                    }
                    {(this.state.showEndGame) &&
                        <EndGameModalComponent closeEndGame={this.closeEndGame} endGame={this.endGame} />
                    }
                    {(this.state.showWinningModal) &&
                        <WinningModalComponent winner={this.state.game.winner} endGame={this.endGame} />
                    }
                    {(this.state.showTurnInfo) &&
                        <div>
                            <Row>
                                <Col md={12}>
                                    <div style={{textAlign: "center", marginTop: "1em"}}>
                                        <h5>Current turn for player: {this.state.game.currentPlayerTurn.playerName}
                                            {(this.state.game.currentPlayerTurn.playerNumber === 1) && <img style={{marginLeft: "0.3em"}} alt={"playerImg"} src={info_player_red} />}
                                            {(this.state.game.currentPlayerTurn.playerNumber === 2) && <img style={{marginLeft: "0.3em"}} alt={"playerImg"} src={info_player_blue} />}
                                            {(this.state.game.currentPlayerTurn.playerNumber === 3) && <img style={{marginLeft: "0.3em"}} alt={"playerImg"} src={info_player_green} />}
                                            {(this.state.game.currentPlayerTurn.playerNumber === 4) && <img style={{marginLeft: "0.3em"}} alt={"playerImg"} src={info_player_yellow} />}
                                        </h5>
                                    </div>
                                    {(this.state.showTurnInfoText !== "") &&
                                        <div style={{textAlign: "center"}} className={"mt-2"}>
                                            <h5>
                                                {this.state.showTurnInfoText}
                                            </h5>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>
                    }
                    {(this.state.showRollDiceContainer) &&
                        <div>
                            {(this.state.showDiceResult === false) &&
                            <Row>
                                <Col md={12}>
                                    <div style={{textAlign: "center", marginTop: "1em"}}>
                                        <Button size={"sm"} style={{width: "10em", marginRight: '1em'}} variant="danger" as="input"
                                                type="button" value="Roll Dice" onClick={this.diceAction}/>
                                    </div>
                                </Col>
                            </Row>
                            }
                            {(this.state.showDiceResult) &&
                                <Row>
                                    <Col md={12}>
                                        <div style={{textAlign: "center", marginTop: "1em"}}>
                                            <h5>Player {this.state.game.currentPlayerTurn.playerName} rolled: {this.state.diceResult}</h5>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </div>
                    }
                    {(this.state.showQuiz) &&
                        <div>
                            {(this.state.game.currentQuestion !== null) &&
                                <QuestionComponent showCorrectAnswer={this.state.showCorrectAnswer}
                                                   correctAnswser={this.state.showCorrectAnswer ? this.state.game.currentQuestion.correctAnswer : null}
                                                   currentQuestion={this.state.game.currentQuestion}
                                                   playerAnswer={this.state.playerAnswer}
                                                   quizAction={this.quizAction} quizClickable={this.state.quizClickable} />
                            }
                        </div>
                    }
                    {(this.state.showProgressbar) &&
                        <div className={gameBoardStyles.meter}>
                            <span style={{width: "100%"}}><span className={gameBoardStyles.animateProgress}/></span>
                        </div>
                    }
                </Container>
            </div>
        );
    }
}

export default GameComponent;
