import React, { Component } from 'react';

import {Modal, Col, Row, Button} from "react-bootstrap";

class EndGameModalComponent extends Component {

    render(){
        return (
            <Modal show={true} size="md">
                <Modal.Header>
                    <Modal.Title>Knowledge Hell End Game</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{fontSize: "0.9em"}}>
                        <Row>
                            <Col md={12}>
                                <strong>Are you sure you want to end the game?</strong>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id={"keepPlayingButton"} size={"sm"} style={{width: '10em', marginRight: '1em'}} variant="danger" as="input"
                            type="button" value="Keep playing" onClick={this.props.closeEndGame}/>
                    <Button id={"endGameButton"} size={"sm"} style={{width: '10em', marginRight: '1em'}} variant="danger" as="input"
                            type="button" value="End Game" onClick={this.props.endGame}/>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default EndGameModalComponent;
