import React, { Component } from 'react';

import {Col, Row, OverlayTrigger, Tooltip} from "react-bootstrap";

import gameBoardStyles from "../style/gameboard.module.css";
import info_player_red from "../images/info_player_red.png";
import info_player_blue from "../images/info_player_blue.png";
import info_player_green from "../images/info_player_green.png";
import info_player_yellow from "../images/info_player_yellow.png";
import field_red from "../images/field_red.png";
import field_blue from "../images/field_blue.png";
import field_green from "../images/field_green.png";
import field_yellow from "../images/field_yellow.png";

class InfoComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            playerOne: props.playerOne,
            playerTwo: props.playerTwo,
            playerThree: props.playerThree,
            playerFour: props.playerFour,
            categoryOne: props.categoryOne,
            categoryTwo: props.categoryTwo,
            categoryThree: props.categoryThree,
            categoryFour: props.categoryFour
        }
    }

    render() {
        return (
            <div style={{textAlign: "left", marginTop: "1em"}}>
                <Row className={"flex-nowrap"}>
                    <Col md={6}>
                        <h4>Player Info</h4>
                    </Col>
                    <Col md={6}>
                        <h4>Categories</h4>
                    </Col>
                </Row>
                <Row className={"flex-nowrap"}>
                    <Col md={6} className={"text-nowrap"}>
                        <p className={gameBoardStyles.pPlayerInfo}>
                            <img style={{marginRight: "0.3em", width:"26px"}} alt={"playerImg"} src={info_player_red} /> Player 1: {this.state.playerOne.playerName}
                        </p>
                    </Col>
                    <Col md={6}>
                        <Row className={"flex-nowrap"}>
                            <Col md={3}>
                                {(this.state.playerOne.categoryOne) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryOne.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryOne"} src={field_red}/>
                                </OverlayTrigger>
                                }
                            </Col>
                            <Col md={3}>
                                {(this.state.playerOne.categoryTwo) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryTwo.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryTwo"} src={field_blue}/>
                                </OverlayTrigger>
                                }
                            </Col>
                            <Col md={3}>
                                {(this.state.playerOne.categoryThree) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryThree.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryThree"} src={field_green}/>
                                </OverlayTrigger>
                                }
                            </Col>
                            <Col md={3}>
                                {(this.state.playerOne.categoryFour) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryFour.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryFour"} src={field_yellow}/>
                                </OverlayTrigger>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={"flex-nowrap"}>
                    <Col md={6} className={"text-nowrap"}>
                        <p className={gameBoardStyles.pPlayerInfo}>
                            <img style={{marginRight: "0.3em", width:"26px"}} alt={"playerImg"} src={info_player_blue} /> Player 2: {this.state.playerTwo.playerName}
                        </p>
                    </Col>
                    <Col md={6}>
                        <Row className={"flex-nowrap"}>
                            <Col md={3}>
                                {(this.state.playerTwo.categoryOne) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryOne.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryOne"} src={field_red}/>
                                </OverlayTrigger>
                                }
                            </Col>
                            <Col md={3}>
                                {(this.state.playerTwo.categoryTwo) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryTwo.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryTwo"} src={field_blue}/>
                                </OverlayTrigger>
                                }
                            </Col>
                            <Col md={3}>
                                {(this.state.playerTwo.categoryThree) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryThree.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryThree"} src={field_green}/>
                                </OverlayTrigger>
                                }
                            </Col>
                            <Col md={3}>
                                {(this.state.playerTwo.categoryFour) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryFour.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryFour"} src={field_yellow}/>
                                </OverlayTrigger>
                                }
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row className={"flex-nowrap"}>
                    <Col md={6} className={"text-nowrap"}>
                        <p className={gameBoardStyles.pPlayerInfo}>
                            <img style={{marginRight: "0.3em", width:"26px"}} alt={"playerImg"} src={info_player_green} /> Player 3: {this.state.playerThree.playerName}
                        </p>
                    </Col>
                    <Col md={6}>
                        <Row className={"flex-nowrap"}>
                            <Col md={3}>
                                {(this.state.playerThree.categoryOne) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryOne.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryOne"} src={field_red}/>
                                </OverlayTrigger>
                                }
                            </Col>
                            <Col md={3}>
                                {(this.state.playerThree.categoryTwo) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryTwo.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryTwo"} src={field_blue}/>
                                </OverlayTrigger>
                                }
                            </Col>
                            <Col md={3}>
                                {(this.state.playerThree.categoryThree) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryThree.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryThree"} src={field_green}/>
                                </OverlayTrigger>
                                }
                            </Col>
                            <Col md={3}>
                                {(this.state.playerThree.categoryFour) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryFour.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryFour"} src={field_yellow}/>
                                </OverlayTrigger>
                                }
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row className={"flex-nowrap"}>
                    <Col md={6} className={"text-nowrap"}>
                        <p className={gameBoardStyles.pPlayerInfo}>
                            <img style={{marginRight: "0.3em", width:"26px"}} alt={"playerImg"} src={info_player_yellow} /> Player 4: {this.state.playerFour.playerName}
                        </p>
                    </Col>
                    <Col md={6}>
                        <Row className={"flex-nowrap"}>
                            <Col md={3}>
                                {(this.state.playerFour.categoryOne) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryOne.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryOne"} src={field_red}/>
                                </OverlayTrigger>
                                }
                            </Col>
                            <Col md={3}>
                                {(this.state.playerFour.categoryTwo) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryTwo.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryTwo"} src={field_blue}/>
                                </OverlayTrigger>
                                }
                            </Col>
                            <Col md={3}>
                                {(this.state.playerFour.categoryThree) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryThree.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryThree"} src={field_green}/>
                                </OverlayTrigger>
                                }
                            </Col>
                            <Col md={3}>
                                {(this.state.playerFour.categoryFour) &&
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id={"tooltip_category_one"}>{this.state.categoryFour.categoryLabel}</Tooltip>}>
                                    <img style={{width: "26px"}} alt={"categoryFour"} src={field_yellow}/>
                                </OverlayTrigger>
                                }
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </div>
        );
    }
}

export default InfoComponent;
