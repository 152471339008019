const validName = (nameElement) => {
    if(typeof nameElement !== "undefined"){
        if(!nameElement.match(/^[a-zA-ZäöüÄÖÜ]+$/)){
            return false;
        }
    }
    else{
        return false;
    }
    return true;
}

const validCategories = (categoryOne, categoryTwo, categoryThree, categoryFour) => {
    const catArray = [categoryOne, categoryTwo, categoryThree, categoryFour];
    return new Set(catArray).size === catArray.length;
}

export {validName, validCategories};
