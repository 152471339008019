export default class Settings {
    constructor(playerOne="PlayerOne", playerTwo="PlayerTwo", playerThree="PlayerThree", playerFour="PlayerFour",
                categoryOneId=9, categoryOneLabel, categoryTwoId=11, categoryTwoLabel, categoryThreeId=22, categoryThreeLabel, categoryFourId=17, categoryFourLabel,
                difficultyLevel="easy") {
        this.playerOne = playerOne;
        this.playerTwo = playerTwo;
        this.playerThree = playerThree;
        this.playerFour = playerFour;
        this.categoryOneId = categoryOneId;
        this.categoryOneLabel = categoryOneLabel;
        this.categoryTwoId = categoryTwoId;
        this.categoryTwoLabel = categoryTwoLabel;
        this.categoryThreeId = categoryThreeId;
        this.categoryThreeLabel = categoryThreeLabel;
        this.categoryFourId = categoryFourId;
        this.categoryFourLabel = categoryFourLabel;
        this.difficultyLevel = difficultyLevel;
        this.categoryOne = new Category(this.categoryOneId, 1, this.categoryOneLabel);
        this.categoryTwo = new Category(this.categoryTwoId, 2, this.categoryTwoLabel);
        this.categoryThree = new Category(this.categoryThreeId, 3, this.categoryThreeLabel);
        this.categoryFour = new Category(this.categoryFourId, 4, this.categoryFourLabel);
    }
}

class Category{
    constructor(categoryId, categoryNumber, categoryLabel) {
        this.categoryId = categoryId;
        this.categoryNumber = categoryNumber;
        this.categoryLabel = categoryLabel;
    }
}
