import React, { Component } from 'react';

import {Col, Row, Button} from "react-bootstrap";

import sanitizeHtml from "sanitize-html";

class QuestionComponent extends Component {

    render() {
        const showAnswerVariantOne = (this.props.showCorrectAnswer && this.props.correctAnswser === 1) ? "success" : (this.props.playerAnswer === "1") ? "warning" : "danger";
        const showAnswerVariantTwo = (this.props.showCorrectAnswer && this.props.correctAnswser === 2) ? "success" : (this.props.playerAnswer === "2") ? "warning" : "danger";
        const showAnswerVariantThree = (this.props.showCorrectAnswer && this.props.correctAnswser === 3) ? "success" : (this.props.playerAnswer === "3") ? "warning" : "danger";
        const showAnswerVariantFour = (this.props.showCorrectAnswer && this.props.correctAnswser === 4) ? "success" : (this.props.playerAnswer === "4") ? "warning" : "danger";

        return (
            <div style={{textAlign: "center"}} className={"mt-2"}>
                <Row>
                    <Col md={12}>
                        <h5>{sanitizeHtml(this.props.currentQuestion.question)}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className={"mt-1"}>
                        <Button size={"sm"} style={{width: '100%'}} variant={showAnswerVariantOne} as="input" answernumber={1}
                                type="button" value={sanitizeHtml(this.props.currentQuestion.answerOne)} onClick={this.props.quizClickable ? this.props.quizAction : undefined}/>
                    </Col>
                    <Col md={6} className={"mt-1"}>
                        <Button size={"sm"} style={{width: '100%'}} variant={showAnswerVariantTwo} as="input" answernumber={2}
                                type="button" value={sanitizeHtml(this.props.currentQuestion.answerTwo)} onClick={this.props.quizClickable ? this.props.quizAction : undefined}/>
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col md={6} className={"mt-1"}>
                        <Button size={"sm"} style={{width: '100%'}} variant={showAnswerVariantThree} as="input" answernumber={3}
                                type="button" value={sanitizeHtml(this.props.currentQuestion.answerThree)} onClick={this.props.quizClickable ? this.props.quizAction : undefined}/>
                    </Col>
                    <Col md={6} className={"mt-1"}>
                        <Button size={"sm"} style={{width: '100%'}} variant={showAnswerVariantFour} as="input" answernumber={4}
                                type="button" value={sanitizeHtml(this.props.currentQuestion.answerFour)} onClick={this.props.quizClickable ? this.props.quizAction : undefined}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default QuestionComponent;
