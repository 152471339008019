import React, { Component } from 'react';

import {Modal, Col, Row, Button} from "react-bootstrap";

import info_player_red from "../images/info_player_red.png";
import info_player_blue from "../images/info_player_blue.png";
import info_player_green from "../images/info_player_green.png";
import info_player_yellow from "../images/info_player_yellow.png";

class WinningModalComponent extends Component {

    render(){
        let playerIcon = "";
        switch(this.props.winner.playerNumber){
            case 1:
                playerIcon = info_player_red;
                break;
            case 2:
                playerIcon = info_player_blue;
                break;
            case 3:
                playerIcon = info_player_green;
                break;
            case 4:
                playerIcon = info_player_yellow;
                break;
            default:
                break;
        }

        return (
            <Modal show={true} size="md">
                <Modal.Header>
                    <Modal.Title>Knowledge Hell WINNER</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{fontSize: "0.9em"}}>
                        <Row>
                            <Col md={12}>
                                <strong>Congratulations!</strong>
                            </Col>
                        </Row>
                        <Row className={"mt-2"}>
                            <Col md={12}>
                                <img style={{width:"26px"}} alt={"playerImg"} src={playerIcon} /> <strong>Player {this.props.winner.playerName} has won the game!</strong>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button size={"sm"} style={{width: '10em', marginRight: '1em'}} variant="danger" as="input"
                            type="button" value="End Game" onClick={this.props.endGame}/>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default WinningModalComponent;
