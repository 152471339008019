import React, { Component } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import Settings from "../game/settings";
import {validName, validCategories} from "../util/validationHelper";


class SettingsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            playerOneName: "",
            playerTwoName: "",
            playerThreeName: "",
            playerFourName: "",
            categoryOne: "9",
            categoryTwo: "11",
            categoryThree: "22",
            categoryFour: "17",
            difficultyLevel: "easy"
        };
        this.categories = [
            {
                label: "General Knowledge",
                value: "9",
            },
            {
                label: "Film",
                value: "11",
            },
            {
                label: "Geography",
                value: "22",
            },
            {
                label: "Science & Nature",
                value: "17",
            },
            {
                label: "History",
                value: "23"
            },
            {
                label: "Sports",
                value: "21"
            },
            {
                label: "Animals",
                value: "27"
            },
            {
                label: "Music",
                value: "12"
            },
            {
                label: "Television",
                value: "14"
            },
            {
                label: "Science Computers",
                value: "18"
            },
            {
                label: "Mythology",
                value: "20"
            }
        ];
        this.difficulties = [
            {
                label: "Easy",
                value: "easy",
            },
            {
                label: "Medium",
                value: "medium",
            },
            {
                label: "Hard",
                value: "hard",
            }
        ];
    }

    handleValidation(){
        let errors = {};
        let formIsValid = true;

        //playerOne
        if(!validName(this.state.playerOneName)){
            formIsValid = false;
            errors["playerOne"] = "Not empty and only letters allowed";
        }

        if(!validName(this.state.playerTwoName)){
            formIsValid = false;
            errors["playerTwo"] = "Not empty and only letters allowed";
        }

        if(!validName(this.state.playerThreeName)){
            formIsValid = false;
            errors["playerThree"] = "Not empty and only letters allowed";
        }

        if(!validName(this.state.playerFourName)){
            formIsValid = false;
            errors["playerFour"] = "Not empty and only letters allowed";
        }

        if(!validCategories(this.state.categoryOne, this.state.categoryTwo, this.state.categoryThree, this.state.categoryFour)){
            formIsValid = false;
            errors["categories"] = "All categories must be different";
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if(!this.handleValidation())
            return;

        const categoryOneLabel = this.categories.find(c => c.value === this.state.categoryOne).label;
        const categoryTwoLabel = this.categories.find(c => c.value === this.state.categoryTwo).label;
        const categoryThreeLabel = this.categories.find(c => c.value === this.state.categoryThree).label;
        const categoryFourLabel = this.categories.find(c => c.value === this.state.categoryFour).label;
        const gameSettings = new Settings(this.state.playerOneName, this.state.playerTwoName, this.state.playerThreeName, this.state.playerFourName,
            this.state.categoryOne, categoryOneLabel, this.state.categoryTwo, categoryTwoLabel,
            this.state.categoryThree, categoryThreeLabel, this.state.categoryFour, categoryFourLabel, this.state.difficultyLevel)
        this.props.game.initNewGame(gameSettings);

        this.props.setState({activePage: 'GAME'});
    }

    handleInputChange = (event) => {
        const target = event.target;
        const id = target.id;
        this.setState({
            [id]: target.value
        });
    }

    render(){
        return (
            <div style={{textAlign: "left", marginTop: "1em"}}>
                <Container>
                    <Form autoComplete="off" onSubmit={this.handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <h3 style={{marginBottom: '1em'}}>Players</h3>
                                <Form.Group controlId="playerOneName">
                                    <Form.Label>Player One</Form.Label>
                                    <Form.Control type="text" onChange={this.handleInputChange} /><span style={{color: "red"}}>{this.state.errors["playerOne"]}</span>
                                </Form.Group>
                                <Form.Group controlId="playerTwoName">
                                    <Form.Label>Player Two</Form.Label>
                                    <Form.Control type="text" onChange={this.handleInputChange} /><span style={{color: "red"}}>{this.state.errors["playerTwo"]}</span>
                                </Form.Group>
                                <Form.Group controlId="playerThreeName">
                                    <Form.Label>Player Three</Form.Label>
                                    <Form.Control type="text" onChange={this.handleInputChange} /><span style={{color: "red"}}>{this.state.errors["playerThree"]}</span>
                                </Form.Group>
                                <Form.Group controlId="playerFourName">
                                    <Form.Label>Player Four</Form.Label>
                                    <Form.Control type="text" onChange={this.handleInputChange} /><span style={{color: "red"}}>{this.state.errors["playerFour"]}</span>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <h3 style={{marginBottom: '1em'}}>Categories</h3>
                                <span style={{color: "red"}}>{this.state.errors["categories"]}</span>
                                <Form.Group controlId="categoryOne">
                                    <Form.Label>Category One</Form.Label>
                                    <Form.Control as="select" onChange={this.handleInputChange} value={this.state.categoryOne}>
                                        {this.categories.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="categoryTwo">
                                    <Form.Label>Category Two</Form.Label>
                                    <Form.Control as="select" onChange={this.handleInputChange} value={this.state.categoryTwo}>
                                        {this.categories.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="categoryThree">
                                    <Form.Label>Category Three</Form.Label>
                                    <Form.Control as="select" onChange={this.handleInputChange} value={this.state.categoryThree}>
                                        {this.categories.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="categoryFour">
                                    <Form.Label>Category Four</Form.Label>
                                    <Form.Control as="select" onChange={this.handleInputChange} value={this.state.categoryFour}>
                                        {this.categories.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <h3 style={{marginBottom: '1em'}}>Difficulty</h3>
                                <Form.Group controlId="difficultyLevel">
                                    <Form.Label>Difficulty Level</Form.Label>
                                    <Form.Control as="select" onChange={this.handleInputChange} value={this.state.difficultyLevel}>
                                        {this.difficulties.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{textAlign: "center"}}>
                                <Button id={"startGameButton"} size={"sm"} style={{width: '10em', marginTop: '2em'}} variant="danger" as="input" type="submit" value="Start Game" />
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        );
    }
}

export default SettingsComponent;
