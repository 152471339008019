import React, { Component } from 'react';

import {Modal, Col, Row, Button} from "react-bootstrap";

import help_goals from "../images/help_goals.gif";
import help_field_effect_burn from "../images/help_field_effect_burn.png";
import help_field_effect_plustwo from "../images/help_field_effect_plustwo.png";
import help_field_effect_steal from "../images/help_field_effect_steal.png";
import help_field_effect_switch from "../images/help_field_effect_switch.png";

class HelpComponent extends Component {

    render() {
        return (
            <Modal show={true} size="lg">
                <Modal.Header>
                    <Modal.Title>Knowledge Hell Rules</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{fontSize: "0.9em"}}>
                        <Row>
                            <Col md={12}>
                                <strong>Goal of the game</strong>
                                <p>
                                    There are four different categories.<br />
                                    Each field represents one category.
                                </p>
                                <p>
                                    Every turn a player rolls a dice and moves around on the field.<br />
                                    When landing on a field that player has to answer a question for that specific category.<br />
                                    A token of that specific category is given to the player, if the answer is correct.<br/>
                                    A player can only have 1 token of a category. (no stacking of tokens)
                                </p>
                                <p>
                                    A player that has answered questions correctly for each category, is able to finish the game.<br />
                                    The outer edge fields are exit points. A player wins the game by landing exactly on that exit field.<br/>
                                    Each player's exit point is at the opposite outer edge. (shown in the following image)
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <strong>Player start and goal</strong>
                                <img alt={"help goals"} src={help_goals} />
                            </Col>
                            <Col md={6}>
                                <strong>Special fields</strong>
                                <p style={{marginBottom: "0"}}>
                                    <img alt={"help goals"} src={help_field_effect_burn} />
                                    <br/>Choose a player that has to skip his next turn.
                                </p>
                                <p style={{marginBottom: "0"}}>
                                    <img alt={"help goals"} src={help_field_effect_switch} />
                                    <br/>Switches the turn order.
                                </p>
                                <p style={{marginBottom: "0"}}>
                                    <img alt={"help goals"} src={help_field_effect_plustwo} />
                                    <br/>Choose a player that has to answer 2 questions the next time.
                                </p>
                                <p style={{marginBottom: "0"}}>
                                    <img alt={"help goals"} src={help_field_effect_steal} />
                                    <br/>Choose a player to steal a token when answering correctly.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button size={"sm"} style={{width: '10em', marginRight: '1em'}} variant="danger" as="input"
                            type="button" value="Close Help" onClick={this.props.closeHelp}/>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default HelpComponent;
